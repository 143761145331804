import { Injectable } from '@angular/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, catchError, from, Observable, of, ReplaySubject, shareReplay, Subject } from 'rxjs';
import { StatehandlerService } from './statehandler.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private _authenticated: boolean = false;
    private readonly _authenticationChanged: BehaviorSubject<boolean> = new BehaviorSubject(this.authenticated);
    private _userProfile = new ReplaySubject<object | null>(1);

    constructor(
        private oauthService: OAuthService,
        private authConfig: AuthConfig,
        private statehandler: StatehandlerService,
    ) {
    }

    public get authenticated(): boolean {
        return this._authenticated;
    }

    public get authenticationChanged(): Observable<boolean> {
        return this._authenticationChanged;
    }

    public async getOIDCUserProm(): Promise<any> {
        try {
            return await this.oauthService.loadUserProfile();
        } catch (error) {
            console.log("auth1", error);

            if (error instanceof HttpErrorResponse) {
                if (error.status === 401) {
                    try {
                        console.log("try reauth")
                        await this.oauthService.refreshToken();
                        return await this.oauthService.loadUserProfile();
                    } catch (error2) {
                        console.log("auth2", error2);
                        this.oauthService.configure(this.authConfig);
                        await this.oauthService.loadDiscoveryDocumentAndTryLogin();
                        this.oauthService.initCodeFlow();
                        return;
                    }
                }
            }

            console.log("auth unhandled");
            throw error;
        }
    }

    public getOIDCUser(): Observable<any> {
        return this._userProfile;
    }

    public async authenticate(setState: boolean = true): Promise<boolean> {
        this.oauthService.configure(this.authConfig);
        this.oauthService.setupAutomaticSilentRefresh();

        this.oauthService.strictDiscoveryDocumentValidation = false;
        await this.oauthService.loadDiscoveryDocumentAndTryLogin();

        this._authenticated = this.oauthService.hasValidAccessToken();

        if (!this.oauthService.hasValidIdToken() || !this.authenticated) {
            const newState = setState ? await this.statehandler.createState().toPromise() : undefined;
            this.oauthService.initCodeFlow(newState);
        }
        this._authenticationChanged.next(this.authenticated);

        // verify that token is truly valid, will try to refresh it if it is not
        // also cache the user profile
        const prom = this.getOIDCUserProm();
        from(prom).pipe(catchError(_ => of(null))).subscribe(this._userProfile);
        await prom;

        return this.authenticated;
    }

    public signout(): void {
        this.oauthService.logOut();
        this._authenticated = false;
        this._authenticationChanged.next(false);
    }
}